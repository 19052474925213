import "./Result.sass";

export default function Result({ result, reset }) {
  return (
    <div className={result ? "overlay show" : "overlay"} onClick={() => reset()}>
      <div className="result_window">      
        <p>{result}</p>
        <button onClick={() => reset()}>OK</button>
      </div>
    </div>
  );
}
