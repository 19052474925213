import "./App.sass";
import { useState, useEffect } from "react";
import Result from "./Result";

function App() {
  const [userScore, setUserScore] = useState(0);
  const [pcScore, setPcScore] = useState(0);
  const [userValue, setUserValue] = useState(0);
  const [pcValue, setPcValue] = useState(0);
  const [userImage, setUserImage] = useState("");
  const [pcImage, setPcImage] = useState("");
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (userScore === 3 || pcScore === 3) {
      const winner = userScore === 3 ? "You won!!!" : "PC won...";
      setTimeout(() => {
        setResult(winner);
      }, 100);
    }
  }, [userScore, pcScore]);

  useEffect(() => {
    const images = ["rock", "paper", "scissors"];
    setUserImage(images[userValue - 1]);
    setPcImage(images[pcValue - 1]);
  }, [userValue, pcValue]);

  const play = (user) => {
    const pc = Math.ceil(Math.random() * 3);

    if (pc !== user) {
      if (pc === 0) {
        user === 1 ? setUserScore((p) => p + 1) : setPcScore((p) => p + 1);
      } else if (pc === 2) {
        user === 0 ? setPcScore((p) => p + 1) : setUserScore((p) => p + 1);
      } else {
        user === 0 ? setUserScore((p) => p + 1) : setPcScore((p) => p + 1);
      }
    }
    setUserValue(user);
    setPcValue(pc);
  };

  const reset = () => {
    setUserImage("");
    setPcImage("");
    setUserScore(0);
    setPcScore(0);
    setUserValue(0);
    setPcValue(0);
    setResult(null);
  };

  return (
    <div className="App">
      <h1 className="title">Rock-Paper-Scissors</h1>

      <div className="score">
        <div>
          <p>
            User score: <span>{userScore}</span>
          </p>
          <img src={userImage && `images/${userImage}.png`} alt="" />
        </div>
        <div>
          <p>
            PC score: <span>{pcScore}</span>
          </p>
          <img src={pcImage && `images/${pcImage}.png`} alt="" />
        </div>
      </div>

      <div className="buttons">
        <img src={`images/rock.png`} alt="" onClick={() => play(1)} />
        <img src={`images/paper.png`} alt="" onClick={() => play(2)} />
        <img src={`images/scissors.png`} alt="" onClick={() => play(3)} />
      </div>
      <Result result={result} reset={reset} />
    </div>
  );
}

export default App;
